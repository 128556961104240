export enum SIGNUP_QUERY_PARAMS {
  WORKFLOW_TYPE = "type",
  DISCORD_ID = "discord-id",
}

/**
 * Define different types of signup workflows that might exist.
 *
 * SIGNUP - the standard workflow that will create a new session cookie. If the user doesn't have an account, one will be created.
 * VALIDATION - the user just needs to validate access to Ninjalerts for an external service, such as Discord.
 */
export enum WORKFLOW_TYPES {
  SIGNUP = "signup",

  // MV RAMBLE: Went with 'verification' as the externally visible term, since that is more commonly used by people to mean a process where you check truth of something. "verification process" is a term normally used to refer to something like this.
  VALIDATION = "discord-verification",
}
